
import Vue from 'vue';
import { VForm } from 'vuetify/types/custom';
import intl from '@/utils/intl';
import validators from '@/utils/validators';
import Http, { HttpError } from '@/utils/Http';

export default Vue.extend({
  mixins: [validators],
  data() {
    return {
      token: '',
      resetForm: false,
      loading: false,
      alertType: 'error',
      alertMessage: '',
      newPassword: null as string | null,
      newErrors: [],
      retPassword: null as string | null,
      retErrors: []
    }
  },
  computed: {
    buttonText(): string {
      return intl.t('views.resetPassword.button');
    },
    newPasswordLabel(): string {
      return intl.t('views.resetPassword.newPassword');
    },
    confirmPasswordLabel(): string {
      return intl.t('views.resetPassword.confirmPassword');
    },
    backToButtonText(): string {
      return intl.t('general.backTo', intl.t('views.login.title'));
    }
  },
  methods: {
    async submit(): Promise<void> {
      if ((this.$refs.resetForm as VForm).validate()) {
        this.loading = true;
        this.alertMessage = '';
        const data = {
          reset_token: this.token,
          new_password: this.newPassword,
          confirm_password: this.retPassword
        };
        try {
          const response = await (new Http()).put('/v1/account/reset-password', JSON.stringify(data));
          (this.$refs.resetForm as VForm).reset();
          this.alertType = 'success';
          this.alertMessage = response.data.message;
        } catch (error) {
          const err = (error as HttpError).response?.data.error;
          this.alertType = 'error';
          if (err) {
            if ('reset_token' in err) {
              err.message = err.reset_token[0];
            }
            if ('new_password' in err) {
              this.newErrors = err.new_password;
            }
            if ('confirm_password' in err) {
              this.retErrors = err.confirm_password;
            }
            this.alertMessage = err.message;
          } else {
            this.alertMessage = intl.t('general.noConnection');
          }
        }
        this.loading = false;
      }
    }
  },
  async mounted() {
    this.loading = true;
    try {
      const data = {
        token: this.$route.params.token
      };
      const response = await (new Http()).put('/v1/account/forgot-password', JSON.stringify(data));
      if (response.data) {
        this.token = response.data.reset_token;
      }
    } catch (error) {
      const err = (error as HttpError).response?.data.error;
      this.alertType = 'error';
      this.alertMessage = err.message;
    }
    this.loading = false;
  }
});
